import React, { Component } from 'react'
import '../../CaldeirasEsquentadores/InfoCE/InfoCE.css';
import ReactPlayer from 'react-player'
import videoGas from '../../../../Imagens/videoEsquentador.mp4'

export default class Info extends Component {
    render() {
        return (
            <div className='infoSectionCE' id='infoE'>
                <br></br>
                <div className='containerEletroCE'>
                <div className='rowEletroCE'>
                    <div className='columnEletroCE'>
                        <div className='caixaCE'>
                        <p className='descCaixaCE'>
                            <strong>O seu esquentador ou caldeira pararam de trabalhar?</strong>
                            <br></br><br></br>

                            Um equipamento de aquecimento de águas, tendo uso diário, vai ao longo do tempo apresentando sinais de desgaste.<br></br> Na WeRepair, contamos com técnicos qualificados para tratar do seu equipamento.<br></br><br></br>
                        <strong>Garantimos uma resposta ao cliente rápida, tendo capacidade de atuar tanto na reparação e manutenção de esquentadores como de Caldeiras a gás, dando uma assistência qualificada e garantia de 2 anos nas peças aplicadas.</strong>
                        <br></br>
                        <br></br>
                        Instalamos e reparamos Esquentadores de Exaustão Natural, Esquentadores Ventilados, Estanques e Ventilados, Caldeiras Murais de Condensação, Termoacumuladores Caldeiras Murais Convencionas, Caldeiras de Chão de Condensação a Gasóleo, Radiadores e Toalheiros.
                        <br></br><br></br>
                        <strong>Em caso de duvida nao hesite em contactar-nos, teremos todo em o gosto em assisti-lo(a).</strong></p>

                        </div>
                    </div>
                    <div className='columnEletroCE'>
                    <video className='videogas' src={videoGas} playsInline autoPlay loop />
                    </div>
                    </div>
                   
                    </div>
                </div>
          
        )
    }
}
