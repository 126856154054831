import React from 'react';
import '../Hero/HeroAC.css';

function HeroAC() {
  return (
    <div className='containerHeroAC' >
      <div className='conteudoHeroAC'>

      <h1 className='tituloAC'><span className='eletro'>ÁGUAS E </span><span className='domesticos'>CANALIZAÇÕES</span></h1>

      </div>

      </div>

  );
}

export default HeroAC;