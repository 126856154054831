import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import {Link as LinkS, animateScroll as scroll} from 'react-scroll';
import {motion} from 'framer-motion';

const STYLES = ['btn--primary', 'btn--outline']
const SIZES = ['btn--medium', 'btn--large'];

export const ButtonSobre = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <LinkS to='about' className='btn-mobile' spy={true} smooth={true} duration={2000}>
              <button
              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </button>
          </LinkS>
      )
};

export const ButtonServiços = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <LinkS to='serviços' className='btn-mobile' spy={true} smooth={true} duration={2000}>
              <motion.button
              whileHover={{
                textShadow: "0px 0px 8px rgb(255,255,255)",
                boxShadow:"0px 0px 4px rgb(0,31 ,80)",
                scale:1.05}}
              className={`btn ${checkButtonStyle} ${checkButtonSize}` }
        
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </LinkS>
      )
};
export const ButtonHero = () =>{
    return(
        <LinkS to='serviços' className='btn' spy={true} smooth={true} duration={2000}>
              <button
              className='btn-servicos'
              >
                Serviços
              </button>
          </LinkS>
    )
};
export const ButtonHeroSomos = () =>{
    return(
        <LinkS to='about' className='btn' spy={true} smooth={true} duration={2000}>
              <button
              className='btn-quemsomos'
              >
                Quem Somos
              </button>
          </LinkS>
    )
};
export const ButtonEletro = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/Eletrodomésticos' className='btn-mobile'>
              <motion.button
              whileHover={{
                  textShadow: "0px 0px 8px rgb(255,255,255)",
                  boxShadow:"0px 0px 8px rgb(255,255,255)",
                  scale:1.05}}

              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </Link>
      )
};
export const ButtonCaldeirasEsquentadores = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/CaldeirasEsquentadores' className='btn-mobile'>
              <motion.button
              whileHover={{
                  textShadow: "0px 0px 8px rgb(255,255,255)",
                  boxShadow:"0px 0px 8px rgb(255,255,255)",
                  scale:1.05}}

              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </Link>
      )
};
export const ButtonEnergiasRenovaveis = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/EnergiasRenováveis' className='btn-mobile'>
              <motion.button
               whileHover={{
                textShadow: "0px 0px 8px rgb(255,255,255)",
                boxShadow:"0px 0px 8px rgb(0,31 ,80)",
                scale:1.05}}
              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </Link>
      )
};
export const ButtonAguas = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/ÁguasCanalizações' className='btn-mobile'>
              <motion.button
               whileHover={{
                textShadow: "0px 0px 8px rgb(255,255,255)",
                boxShadow:"0px 0px 8px rgb(0,31 ,80)",
                scale:1.05}}
              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </Link>
      )
};
export const ButtonGas = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/Gás' className='btn-mobile'>
              <motion.button
               whileHover={{
                textShadow: "0px 0px 8px rgb(255,255,255)",
                boxShadow:"0px 0px 8px rgb(255,255,255)",
                scale:1.05}}
              className={`btn ${checkButtonStyle} ${checkButtonSize}`}
              onClick={onClick}
              type={type}
              >
                  {children}
              </motion.button>
          </Link>
      )
};
export const ButtonInicio = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)? buttonStyle:  STYLES[0];

      const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

      return(
          <Link to='/' className='btn-mobile'>
              <button
              className={`btn ${checkButtonStyle} ${checkButtonSize}` }
        
              onClick={onClick}
              type={type}
              >
                  {children}
              </button>
          </Link>
      )
};
