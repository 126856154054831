import React, { Component } from 'react'
import '../About/About.css'
import { FcAssistant } from "react-icons/fc";
import { FcCollaboration } from "react-icons/fc";
import { FcCamcorderPro } from "react-icons/fc";
import { AiFillSafetyCertificate } from "react-icons/ai";
import hugo from '../../Imagens/Loja/hugopng.png';
export default class WhyUs extends Component {
    render() {
        return (
            <div>
                 <div className='whyus'>
                <h1 className="tituloAbout"><div className='linhaesq'/>
                Porquê a &nbsp;<span className='we'>We</span><span className='repair' >Repair</span>
                <div className='linhadt' /></h1>
       
                <div class="rowAbout">
                    <div class="columnAbout">
                        <div className='icons'>
                        <FcAssistant size='50' color='rgb(245, 130, 31)'/>
                        
                        <h3 className='titulodestaques'>Linha de Apoio 24/7</h3>
                                <p className='descdestaques'>Dispomos de uma equipa técnica para lhe poder auxiliar em qualquer assunto de segunda a sexta feira</p>
                                </div>
                    <br></br><br></br><br></br>
                    
                    <div className='icons'>
                    <FcCollaboration size='50'/>
                    </div>
                        <h3 className='titulodestaques'>Flexibilidade no agendamento</h3>
                                    <p className='descdestaques'>Contactamos com o cliente no máximo de 24h após a solicitação do serviço, fornecendo flexibilidade no agendamento.</p>
                        
                       
                    </div>
                    <div
                    className='columnAbout'>
                        <img className='funcionario' src={hugo} />
                    </div>
                    <div class="columnAbout">
                    <div className='icons'>
                    <AiFillSafetyCertificate size='50' color='rgb(245, 130, 31)'/>
                    </div>
                    <h3 className='titulodestaques'>Reparação de equipamentos</h3>
                                <p className='descdestaques'>Reparamos qualquer tipo de equipamento independentemente da marca, relacionado com os nossos serviços na hora.</p>
                    <br></br><br></br><br></br>
                        <div className='icons'>
                        <FcCamcorderPro size='50'/>
                        </div>
                        <h3 className='titulodestaques'>Componentes Filmados</h3>
                                    <p className='descdestaques'>No ato do serviço, filmamos os aspetos e os passos todos para uma melhor organização e comprovação do estado dos seus componentes.</p>
                    </div>
                </div>
                </div>
                <br></br><br></br><br></br>
            </div>
        )
    }
}
