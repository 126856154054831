import React, { Component } from 'react'
import './GaleriaE.css'
import videoE from '../../../../Imagens/Serviços/maquinaLavar.mp4'
import videoE2 from '../../../../Imagens/videoE.mp4'
export default class GaleriaE extends Component {
    render() {
        return (
            <div>
                <div className='rowGaleriaE'>
                    <div className='columnGaleriaE'>
                        <video className='videoE' src={videoE} autoPlay loop muted/>
                    </div>
                    <div className='columnGaleriaE'>
                    <video className='videoE' src={videoE2} autoPlay loop muted></video>                  
                    </div>
                 
                </div>
            </div>
        )
    }
}
