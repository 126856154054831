import React, { Component } from 'react'
import './Fugas.css'
import fugagas from '../../../../Imagens/Serviços/fugagas.png';

export default class Fugas extends Component {
    render() {
        return (
            <div>
              <div className='fugascontainer'>
                  <div className='tituloHeaderGas'>
                      <h1>Reparação de Fugas Gás</h1>
                  </div>
                <div className='rowFugas'>
                    <div className='columnFugas'>
                        <p className='descFugaGas'>
                        Em caso de fugas internas nas redes mais antigas (por dentro das paredes) é aplicado o método <strong>tratamento interno</strong> da instalação com <strong>Polyfill PII</strong> – sistema rápido, seguro e eficaz na eliminação de fugas de gás em juntas de ligação de tubagens.<br></br><br></br>

                        O tratamento pode ser aplicado na reparação de fugas de gás em colunas montantes, fogos individuais, instalações industriais, etc. Este método oferece a solução mais rápida e económica, e põe um ponto final no perigo associado às fugas de gás.<br></br><br></br>

                        O Polyfill PII é um produto certificado que satisfaz as exigências da <strong>norma europeia DIN EN 13090 de 01/2001</strong>, que foi desenvolvido especialmente para produzir a estanquidade necessária nas juntas de ligação danificadas de redes internas de distribuição. É compatível com gases combustíveis, livre de solventes, não combustível, e a sua composição química é inofensiva para o ambiente.
                        </p>
                    </div>
                    <div className='columnFugas'>
                        <p className='descFugaGas'><strong>Vantagens do tratamento interno:</strong><br></br><br></br>
                         •	Sistema de vedação seguro, com óptimos resultados finais.<br></br>
                         •	Garantia de poucos distúrbios para residentes – não são necessários trabalhos de construção civil<br></br>
                         •	Processo rápido – período de interrupção de gás muito reduzido.<br></br>
                         •	Custos de reparação reduzidos – substancialmente mais económico que a substituição de tubagens.<br></br>
                         •	Produto amigo do ambiente.
                        </p>
                        <img src={
                            fugagas
                        }/>
                    </div>
                </div>
                <div className='rowFugas'>

                </div>
                </div>  
            </div>
        )
    }
}
