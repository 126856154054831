import React from 'react'
import '../Contactos/Contactos.css'
import { IoLogoInstagram } from "react-icons/io5";
import { IoLogoFacebook } from "react-icons/io5";
import { IoLocation } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
const Contactos = () => {
    return (
        <div className='contactos'>
            <h1 className='tituloAboutContactos'>Contactos</h1>
                <div className='columnaa'>
                         
                         
                    <div className='iconscontacto'>
                        <a href='https://www.facebook.com/WeRepair-Assistência-Técnica-Lda-108263481313409' target="_blank">
                        <motion.div 
                            whileHover={{
                                scale:1.5
                                
                            }}
                        className='iconContacto'>
                         <IoLogoFacebook size='60' color='white' style={{marginRight: '20px'}}/>
                         </motion.div>
                         </a>
                         <a href='https://www.instagram.com/werepair_lda/' target="_blank">
                         <motion.div 
                         whileHover={{
                            scale:1.5
                            
                        }}
                         className='iconContacto'>
                         <IoLogoInstagram size ='60' color='white'/>
                         </motion.div>
                         </a>
                     </div>
            
                  
             </div>
                <div className='roww'>
                
                    <div className='columnn'>
                        <div className='solid'></div>
                    <IoLocation  size='40' color='white' className='iconscc'></IoLocation><h3 className='headerContactos'>Morada</h3>
                    <p className='txtcc'>Rua Luis de Camões nº3 2610-104 AMADORA</p>   
                    </div>
                    <div className='columnn'>

                    <IoCall size='40'color='white'className='iconscc'></IoCall><h3 className='headerContactos'>Telefone</h3><p className='txtcc'>913 799 800 - 217 648 901</p>
                    </div>
                    <div className='columnn'>

                    <MdEmail size='40'color='white'className='iconscc'></MdEmail><h3 className='headerContactos'>E-mail</h3><p className='txtcc'>geral.werepair@gmail.com</p>
                    </div>
                                     
                   
                <div className='solid'></div>
                    
                    
            
                 </div>


                 <iframe width="100%" height="295" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=357&amp;height=295&amp;hl=en&amp;q=Rua%20Luis%20de%20Camoes%203%20Buraca%20Lisbon+(WeRepair)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=ac9350becc46703f1f1e5e16ca0b3cfca32293c3'></script>      
        </div>
    
    )
}

export default Contactos
