import React, {Component} from 'react'
import '../../EnergiasRenovaveis/Info/InfoER.css';
import painelSolar from '../../../../Imagens/Serviços/painelSolar.jfif';

export default class Info extends Component {
    render() {

        return (
            <div className='infoSectionER' id='infoE'>
                <br></br>
                <div className='headerInfoER'>
                    <div className='containerEletroER'>
                        <div className='rowEletroER'>
                            <div className='columnEletroER'>
                                <div className='caixaER'>
                                    <p className='descCaixaER'>A <strong>WeRepair</strong>, garante aos seus clientes uma vasta gama de produtos e serviços. Temos ao seu dispor, técnicos credenciados na reparação e manutenção dos seus equipamentos. De forma a proporcionar ao nosso cliente conforto e segurança, reparamos o seu equipamento em casa garantindo uma atuação rápida e eficaz.
                                    <br></br><br></br>•	Instalação de Bombas de Calor
                                    <br></br><br></br>•	Instalação de Paneis Solares
                                    <br></br><br></br>•	Reparação de Bombas de Calor
                                    <br></br><br></br>•	Reparação de Paineis Solares    
                                    </p>
                                </div>
                            </div>
                            <div className='columnEletroER'>
                                <img src={painelSolar}
                                    className='imgPainelSolar'/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
