import React from 'react';
import './App.css';
import Home from './components/pages/HomePage/Home';
import Eletrodomesticos from './components/pages/Eletrodomésticos/Eletrodomésticos';
import aguasCanalizacoes from './components/pages/ÁguasCanalizações/aguasCanalizacoes';
import energiasRenovaveis from './components/pages/EnergiasRenovaveis/EnergiasRenovaveis';
import Gas from './components/pages/Gás/Gás';
import CaldeirasEsquentadores from './components/pages/CaldeirasEsquentadores/CaldeirasEsquentadores';
import {BrowserRouter as Router,Switch, Route, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import 'font-awesome/css/font-awesome.min.css';
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from './ScrollToTop';


function App() {
  const location = useLocation();

  return (
      <AnimatePresence>
      <ScrollToTop />
      <Switch location={location} key={location.key}>
        <Route path='/' exact component={Home} />
        <Route path='/Eletrodomésticos' exact component={Eletrodomesticos} />
        <Route path='/ÁguasCanalizações' exact component={aguasCanalizacoes} />
        <Route path='/Gás' exact component={Gas} />
        <Route path='/EnergiasRenováveis' exact component={energiasRenovaveis} />
        <Route path='/CaldeirasEsquentadores' exact component={CaldeirasEsquentadores} />
        <Route path='/*' exact component={Home} />
      </Switch>
      </AnimatePresence>

  );
}

export default App;
