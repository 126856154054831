import React, {useEffect} from 'react';
import HeroSection from '../../Hero/HeroSection';
import Contactos from '../../Contactos/Contactos';
import Footer from '../../Footer/Footer';
import About from '../../About/About';
import ServicoE from '../../Serviços/ServicosE';
import ServicoG from '../../Serviços/ServicosG';
import ServicoA from '../../Serviços/ServicosA';
import ServicosCE from '../../Serviços/ServicosCE';
import ServicosER from '../../Serviços/ServicosER';
import { Gas, Eletrodomesticos, Aguas, CaldeirasEsquentadores, EnergeriasRenovaveis } from './Data';
import Navbar from '../../navbar/Navbar';
import WhyUs from '../../whyus/WhyUs';
import Zonas from '../../Zonas/Zonas';
import Marcas from '../../Marcas/Marcas';
import Galeria from '../../pages/HomePage/Galeria/Galeria'
import ScrollingWrapper from '../../toTop/ToTop';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


function SlideE({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.75, delay:0.5 }}
      variants={{
        visible: { y: 0, opacity:1 },
        hidden: { y: -200, opacity:0 }
      }}
    >
      {children}
    </motion.div>
  );
}
function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 }
      }}
    >
      {children}
    </motion.div>
  );
}




function Home() {
  return (
    <>
    <motion.div
    exit={{ opacity: 0 }}
    > <div className='hero-containerr'>
      <Navbar />
      <HeroSection  />
      </div>
      <div className='body-container' >
      <div id='sobre' ></div>
       <About />
      <h2 id='serviços'></h2>
      <SlideE>
      <ServicoE {...Eletrodomesticos}/> 
      </SlideE>
      <SlideE>
      <ServicoA {...Aguas} />
      </SlideE>
      <SlideE>
      <ServicoG {...Gas} />
      </SlideE>
      <SlideE>
      <ServicosER {...EnergeriasRenovaveis} />
      </SlideE>
      <SlideE>
      <ServicosCE {...CaldeirasEsquentadores} />
      </SlideE> 
        <WhyUs />
        <SlideE>
        <Zonas />
        </SlideE>
        <FadeInWhenVisible>
      <Marcas  />
      </FadeInWhenVisible>
      <div id='contactos'></div>
      <Contactos />
      </div>
      <Galeria />
      <Footer />
      
      <ScrollingWrapper></ScrollingWrapper>
      </motion.div>
    </>
  );
}

export default Home;
