
  export const Gas = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Serviços de',
    headline: 'Gás',
    description:
      'Efetuamos reparações de fugas de gás, detetamos e reparamos monóxido de carbono e instalamos Colunas/Apartamentos em cobre.',
    buttonLabel: 'Saber Mais',
    imgStart: 'start',
    alt: 'Vault'
  };
  
  export const Eletrodomesticos = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Reparação de',
    headline: 'Eletrodomésticos',
    description:
      "Efetuamos reparações de máquinas de lavar roupa, máquinas de lavar loiça, secadores de roupa, placas, fogões, exaustores e fornos.",
    buttonLabel: 'Saber Mais',
    imgStart: 'start',
    img: 'https://www.noticiasdeviseu.com/wp-content/uploads/2020/10/sulsicuro-appliances-531x400.png',
    alt: 'Vault'
  };
  
  export const Aguas = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Serviços de',
    headline: 'Águas e Canalizações',
    description:
      'Instalamos água em inox, água em multicamada, colunas novas e reparamos ruturas de água nas suas canalizações.',
    buttonLabel: 'Saber Mais',
    imgStart: '',
    alt: 'Vault'
  };
  export const CaldeirasEsquentadores = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Serviços de',
    headline: 'Caldeiras e Esquentadores',
    description:
      'Efetuamos reparações e instalações de esquentadores ventilados, estanques e ventilados, esquentaores de exaustão natural, caldeiras de condensação, caldeiras convencionais, radiadores e toalheiros.',
    buttonLabel: 'Saber Mais',
    imgStart: 'start',
    alt: 'Vault'
  };
  export const EnergeriasRenovaveis = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Serviços de',
    headline: 'Energias Renováveis',
    description:
      "Efetuamos instalações e reparações de bombas de calor e paneis solares. Pensando sempre num futuro melhor para si e para todos.",
    buttonLabel: 'Saber Mais',
    imgStart: '',
    alt: 'Vault'
  };
  
  
