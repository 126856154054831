import React, { Component } from 'react'
import './Galeria.css'
import imagem1 from '../../../../Imagens/Loja/loja1.JPG'

import imagem3 from '../../../../Imagens/Loja/loja3.JPG'
import imagem4 from '../../../../Imagens/Loja/loja4.JPG'
export default class Galeria extends Component {
    render() {
        return (
            <div>
                <div className='galeriaContainer'>
                    <div className='rowGaleria'>
                        <div className='columnGaleria'>
                            <img className='imgGaleria' src={imagem1} />
                        </div>       

                        <div className='columnGaleria'>
                        <img className='imgGaleria' src={imagem3} />
                        </div>   
                        <div className='columnGaleria'>
                        <img className='imgGaleria' src={imagem4} />
                        </div>   
                                    
                    </div>
                </div>
            </div>
        )
    }
}
