import React from 'react';
import './Footer.css';
import reclamacoes from '../../Imagens/reclamacoes.png'
import {Link} from 'react-browser-router'

function Footer() {
  return (
    <div className='footer-container'>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>© Copyright <strong>WeRepair.</strong> Direitos reservados</small>
          <a target="_blank" href="http://www.livroreclamacoes.pt"><img className='imgreclamacoes' src={reclamacoes}/></a>
          <div className='linkss'>
            <small style={{color:'white'}}>Developed By RicardoJRO</small>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;