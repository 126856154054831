import React, { Component } from 'react'
import './Zonas.css'
import pt from '../../Imagens/portugal.png'

export default class Zonas extends Component {
    render() {
        return (
            <div>
                <div className='zonas'>
                    <div className='containerZonas1'>
                        <div className='columnAtuamos'>
                            <h1 className='atuamos'>Atuamos Nas Zonas De</h1>
                            <h4 className='descAtuamos'>Lisboa, Oeiras, Odivelas, Loures, Cascais, Sintra, Mafra, Sacavém, Barreiro, Caparica, Amadora e Benfica onde disponibilizamos todos os serviços de reparação de eletrodomésticos, canalizações e avarias de gás.</h4>
                        </div>
                        <div className='portugal'>
                            <img className='portugal' src={pt} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
