import React, { useState, useEffect } from 'react';
import {  ButtonServiços } from '../Button/Button';

import './Navbar.css';
import logo from '../../Imagens/banner.png'
import {Link,animateScroll as scroll} from 'react-scroll';
import {motion} from 'framer-motion';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const toggleUp = () =>{
    scroll.scrollToTop();
  }
  const home = () =>{
    setClick(false);
    scroll.scrollToTop();
  }
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <motion.nav className='navbar'
      initial={{y:-80}}
      animate={{y:0}}
      transition={{delay: 1, duration:1.5}}
      >
        <div className='navbar-container'>
          <Link onClick={toggleUp}>
        <img src={logo} alt="" className='navbar-logoP' ></img>
        </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times ' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <motion.li
            
            whileHover={{scale: 1.1, originX:0 ,}}
            transition={{type: 'spring', stiffness: 300}}
            className='nav-item'  >
              <motion.Link
              
              whileHover={{scale: 1.1, originX:0 ,}}
              transition={{type: 'spring', stiffness: 300}}
               to='home' className='nav-links' onClick={home } spy={true} smooth={true} duration={2000}>
              Início
              </motion.Link>
            </motion.li>
            <motion.li
            
            whileHover={{scale: 1.1, originX:0 ,}}
            transition={{type: 'spring', stiffness: 300}}
            className='nav-item'>
            <Link
              className="nav-links" to='sobre' spy={true} smooth={true} duration={2000} onClick={closeMobileMenu}>
                Sobre Nós
              </Link>
            </motion.li>
           
            <motion.li 
            whileHover={{scale: 1.1, originX:0 ,}}
            transition={{type: 'spring', stiffness: 300}}
            className='nav-item' >
              <Link
                to='contactos'
                className='nav-links'
                spy={true} smooth={true} duration={2000} 
                onClick={closeMobileMenu}
              >
                Contactos
              </Link>
            </motion.li>

            <li>
              <Link
                to='serviços'
                spy={true} smooth={true} duration={2000}
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Serviços
              </Link>
            </li>
          </ul>
          
          <Link to='serviços' className='btn-mobile' spy={true} smooth={true} duration={2000}>{button && <ButtonServiços buttonStyle='btn--primary'>Serviços</ButtonServiços>}</Link>
        </div>
      </motion.nav>
    </>
  );
}

export default Navbar;