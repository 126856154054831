import React, { Component } from 'react'
import {motion} from 'framer-motion'
import '../../CaldeirasEsquentadores/CardsCE/CardsCE.css'
import esqexaustao from '../../../../Imagens/Serviços/esquentadorexaustao.jpg'
import esqventilado from '../../../../Imagens/Serviços/esquentadorventilado.jpg'
import esqestanqueventilado from '../../../../Imagens/Serviços/esqestanqueventilado.jpg'
import caldeiracondensada from '../../../../Imagens/Serviços/caldeiracondensada.jpg'
import caldeirachao from '../../../../Imagens/Serviços/caldeirachao.jpg'
import caldeiraconvencional from '../../../../Imagens/Serviços/caldeiraconvencional.jpg'
import radiadores from '../../../../Imagens/Serviços/radiadores.jpg'




function Card() {
    return (
        <div className='cardsCEBackground'>
        <div className='cardsContainerCE'>
        <div className='tituloCardsContainerCE'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Instalamos e Reparamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsCE'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Esquentadores Exaustão Natural</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={esqexaustao}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Esquentadores Ventilados</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={esqventilado}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Esquentadores Estanques e Ventilados</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={esqestanqueventilado}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Caldeiras Murais Condensação</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={caldeiracondensada}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Caldeiras Murais Convencionais</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={caldeiraconvencional}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Radiadores e Toalheiros</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={radiadores}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsCE'>
                        <div className='tituloCardCE'>
                            <h3>Caldeira de Chão de Condensação a Gasóleo</h3>
                        </div>
                        <div className='cardImgCCE'>
                        <img className='cardImgCE' src={caldeirachao}></img>
                        </div>
                    </motion.div>
            </div>
           
        </div>
        
    </div>
  
    );
  }
  
  export default Card;
