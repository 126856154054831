import React from 'react';
import '../HeroCE/HeroCE.css';

function HeroCE() {
  return (
    <div className='containerHeroCE' >
      <div className='conteudoHeroCE'>

      <h1 className='tituloCE'><span className='eletro'>CALDEIRAS E </span><span className='domesticos'>ESQUENTADORES</span></h1>

      </div>

      </div>

  );
}

export default HeroCE;