import React, { Component } from 'react'
import {motion} from 'framer-motion'
import '../../Gás/Cards/CardsG.css'
import gas from '../../../../Imagens/gasL.jpg'
import co from '../../../../Imagens/gasC.jpg'
import cobre from '../../../../Imagens/cobre.jpg'
import CO from '../../../../Imagens/Serviços/CO.jfif'

function Card() {
    return (
        <div className='cardsGBackground'>
        <div className='cardsContainerG'>
        <div className='tituloCardsContainerG'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Efetuamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsG'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsG'>
                        <div className='tituloCardG'>
                            <h3>Reparações de Fugas de Gás (Sem Danificar Paredes)</h3>
                        </div>
                        <div className='cardImgG'>
                        <img className='cardImgG' src={gas}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsG'>
                        <div className='tituloCardG'>
                            <h3>Deteção e Reparação de Monóxido de Carbono (CO)</h3>
                        </div>
                        <div className='cardImgG'>
                        <img className='cardImgG' src={co}></img>
                        </div>
                    </motion.div>
            </div>
            <div className='tituloCardsContainerG'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Instalamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsG'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsG'>
                        <div className='tituloCardG'>
                            <h3>Colunas Novas/Apartamentos em Cobre</h3>
                        </div>
                        <div className='cardImgG'>
                        <img className='cardImgG' src={cobre}></img>
                        </div>
                    </motion.div>
            </div>
        </div>
        
    </div>
  
    );
  }
  
  export default Card;
