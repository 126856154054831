import React from 'react'
import './About.css'
import banner from '../../Imagens/banner.png'
import loja2 from '../../Imagens/Loja/loja1tsp.png'
import Slider from "react-slick";
import loja1 from '../../Imagens/Loja/loja3tsp.png'
import loja3 from '../../Imagens/Loja/loja2tsp.png'
import loja4 from '../../Imagens/Loja/loja4tsp.png'
function About () {
    
        const settings = {
            dots: false,
            fade: true,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear",
           
          }
    
    return(

<section className='containerAbout'>
            
                <div class="rowAboutt" >
                <div class="columnaSlider">
                        <Slider className='sliderAbout' {...settings}>
                            <div className='fader'>
                                <img className='imgAbout' src={loja1} />
                            </div>
                            <div>
                                <img className='imgAbout' src={loja2} />
                            </div>   
                            <div>
                                <img className='imgAbout' src={loja3} />
                            </div>       
                            <div>
                                <img className='imgAbout' src={loja4} />
                            </div>   
                        </Slider>  
                    </div>
                    <div class="columna">
                        <img className='bannerAbout' src={banner} />
                    <p className='descAbout'>A <strong>WeRepair, Assistência Técnica Lda</strong> é uma empresa que tem como princípio base a satisfação dos nossos clientes.<br></br><br></br> Fundada em <strong>2012</strong> conta com uma equipa de técnicos com mais de 15 anos de experiência na área de assistência técnica a pequenos e grandes eletrodomésticos multimarcas.
                       <br></br><br></br> <strong>Desenvolvemos a nossa atividade levando até si qualidade e segurança, sendo o nosso foco a reparação de eletrodomésticos no conforto do seu lar</strong>.<br></br><br></br> À distância de um telefonema, temos ao seu dispor uma equipa que irá acompanhar o processo desde o agendamento à conclusão. 
                        <br></br>A <strong>WeRepair, Assistência Técnica, LDA</strong>, dispõe ainda de instalações próprias para a reparação dos equipamentos, onde a higiene, a qualidade e a segurança são o nosso maior foco, sempre que não seja possível a reparação na sua habitação.</p>
                    </div>
                   
                </div>

                </section>
            
    )
}
export default About;
