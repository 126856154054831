import React from 'react';
import './HeroSection.css';
import {Link,animateScroll as scroll} from 'react-scroll';
import { BiMouse } from "react-icons/bi";
import {FaHandPointer} from "react-icons/fa"
import tecnico1 from '../../Imagens/hugopng.png'
import {motion} from 'framer-motion';
import geo from '../../Imagens/geologia.png'
function HeroSection() {
  return (
    <div className='hero-container' >
      <motion.div className='conteudoHero'
        initial={{opacity: 0}}
        animate={{opacity:1}}
        transition={{ duration:1.5}}
        >
        <h1 className='we'><u>We</u><span className="repair"><u>Repair</u></span></h1>
        <p>A Sua Empresa De Reparação
        </p>
        
      </motion.div>
      <motion.div
       initial={{opacity: 0}}
       animate={{opacity:1}}
       transition={{ duration:1.5,delay:2.5}}>
         
        </motion.div>
      <motion.div
      initial={{opacity: 0}}
      animate={{opacity:1}}
      transition={{ duration:1.5,delay:1.5}}
      >
      <Link to='sobre' spy={true} smooth={true} duration={1000} className='rato'><BiMouse size='80' className='scroll'  /></Link>
      <Link to='sobre' spy={true} smooth={true} duration={1000} className='finger'><FaHandPointer size='80' className='scroll'  /></Link>
      </motion.div>
      <motion.div className='linhaContactos' style={{opacity:'0.9'}} opacity='0.9'
      initial={{y:79, opacity:0}}
      animate={{y:0, opacity:1}}
      transition={{delay: 1, duration:1}}
      >
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ duration: 1, delay:2.5 }}
        
  
      className='columnHeroGeoo'>
          <img className='bannerGeo' src={geo} />
        </motion.div>
        <motion.div
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{ duration: 1, delay:2.5 }}
          
    
        className='columnHero'>
        <h3 className='h3Hero' >Detentora da credencial, nº94/EI Tipo A+B, emitida pela Direção-Geral de Energia e Geologia</h3>
        </motion.div>
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ duration: 1, delay:2.5 }}
        
  
      className='columnHeroGeo'>
          <img className='bannerGeo' src={geo} />
        </motion.div>
        </motion.div>
      </div>
      

  );
}

export default HeroSection;