import React, { Component } from 'react'
import {motion} from 'framer-motion';
import './Info.css';
import eletrodomesticos from '../../../../Imagens/eletrodomesticosInfo.jpg'
import { RiCake2Line } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { FaTruck } from "react-icons/fa";
import { FaPeopleCarry } from "react-icons/fa";
export default class Info extends Component {
    render() {
        
        return (
            <div className='infoSection' id='infoE'>
                <div className='barra'>
                    <div className='rowIcones'>
                        <div className='columnIcons'>
                            <motion.div 
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{ duration: 1, delay:0.75 }}
                            className='iconesInfo'>
                                <RiCake2Line size={50} color='white' className='icones'></RiCake2Line>
                                <h1 className='h1_icons'>9</h1>
                                <p className='descIcones'>Anos de Experiência</p>
                            </motion.div>
                                
                            </div>
                            <div className='columnIcons'>
                            <motion.div 
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{ duration: 1, delay:1 }}
                            className='iconesInfo'>
                                <FaTruck size={50} color='white' className='icones'></FaTruck>
                                <h1 className='h1_icons'>1000+</h1>
                                <p className='descIcones'>Serviços por Ano</p>
                            </motion.div>
                                
                            </div>
                            <div className='columnIcons'>
                            <motion.div
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{ duration: 1, delay:1.25 }}
                            className='iconesInfo'>
                                <IoIosPeople size={50} color='white' className='icones'></IoIosPeople>
                                <h1 className='h1_icons'>32</h1>
                                <p className='descIcones'>Membros e Fucionários</p>
                            </motion.div>
                            </div>
                            <div className='columnIcons'>
                            <motion.div 
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{ duration: 1, delay:1.50 }}
                            className='iconesInfo'>
                                <FaPeopleCarry size={50} color='white' className='icones'></FaPeopleCarry>
                                <h1 className='h1_icons'>18</h1>
                                <p className='descIcones'>Parceiros</p>
                            </motion.div>
                    
                            </div>
                        </div>
                </div>
                <br></br>
                
                
                <motion.div 
                
                className='containerEletro'>
               
                <div className='rowEletro'>
                    <div className='columnEletro'>
                        <div className='caixa'>
                       
                        <p className='descCaixa'><strong>Contamos com uma equipa técnica qualificada e experiente na reparação e manutenção dos seus eletrodomésticos</strong>.<br></br><br></br>

                        Efetuamos reparação de máquinas de lavar roupa, máquinas de lavar loiça, Secadores de roupa, placas, fogões, exaustores e fornos.<br></br><br></br>

                        <strong>Os nossos técnicos contam com uma vasta experiência multimarcas com 100% de satisfação por parte dos nossos clientes. Damos prioridade à reparação em sua casa, para garantir a utilização imediata do seu equipamento após a nossa intervenção</strong>.<br></br><br></br>

                        Ainda assim, dispomos de uma oficina capacitada para receber e reparar o seu equipamento sempre que necessário, com a máxima eficacia e segurança, garantido um transporte seguro e adequado dos seus eletrodomésticos.</p>
                        </div>
                    </div>

                    <div className='columnEletro'>
                    <img className='imgEletro' src={eletrodomesticos} />
                    </div>
                    </div>
                   
                    </motion.div>
                </div>
        
        )
    }
}
