import React, { Component } from 'react'
import {motion} from 'framer-motion'
import '../../EnergiasRenovaveis/Cards/CardsER.css'
import bombacalor from '../../../../Imagens/BombaCalor.png'
import painelsolar from '../../../../Imagens/painelSolar.jpg';

function CardsER() {
    return (
        <div className='cardsERBackground'>
        <div className='cardsContainerER'>
        <div className='tituloCardsContainerER'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Instalamos e Reparamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsER'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsER'>
                        <div className='tituloCardER'>
                            <h3>Bombas De Calor</h3>
                        </div>
                        <div className='cardImgER'>
                        <img className='cardImgER' src={bombacalor}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsER'>
                        <div className='tituloCardER'>
                            <h3>Paineis Solares</h3>
                        </div>
                        <div className='cardImgER'>
                        <img className='cardImgER' src={painelsolar}></img>
                        </div>
                    </motion.div>
            </div>
         
        </div>
        
    </div>
  
    );
  }
  
  export default CardsER;
