import React from 'react';
import '../Hero/HeroG.css';

function HeroG() {
  return (
    <div className='containerHeroG' >
      <div className='conteudoHeroG'>

      <h1 className='tituloG'><span className='eletro'>SERVIÇOS DE </span><span className='domesticos'>GÁS</span></h1>

      </div>

      </div>

  );
}

export default HeroG;