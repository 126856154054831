import React, { useEffect, useState } from "react";
import { IoArrowUpCircle } from "react-icons/io5";;
import './toTop.css';
import {animateScroll as scroll} from 'react-scroll';
import { motion, useAnimation } from "framer-motion";
import { IconName } from "react-icons/cg";
  
export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  

  
    useEffect(() => {
      window.addEventListener("scroll", toggleVisibility);
    }, []);
  
    return (
      <motion.div 
      whileHover={{
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow:"0px 0px 4px rgb(0,31 ,255)",
        
      }}
      className="scroll-to-top">
        {isVisible && 
          <motion.div 
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:0.5}}
          exit={{ opacity: 0 }}
          >
            <IoArrowUpCircle stroke={200} onClick={scroll.scrollToTop} className='iconToTop' size={60}></IoArrowUpCircle>
            <motion.p  onClick={scroll.scrollToTop} className='toTopDesc'>Ir para o Topo</motion.p>
          </motion.div>}
      </motion.div>
    );
  }

