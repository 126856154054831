import React from 'react';
import './heroE.css';

import { IoIosArrowDropdown } from "react-icons/io";
import {Link,animateScroll as scroll} from 'react-scroll';
function HeroE() {
  return (
    <div className='containerHeroEletro' >
      <div className='conteudoHeroEletro'>

      <h1 className='tituloEletro'><span className='eletro'>ELETRO</span><span className='domesticos'>DOMÉSTICOS</span></h1>

      </div>

      </div>

  );
}

export default HeroE;