import React, { Component } from 'react'
import {motion} from 'framer-motion'
import '../../ÁguasCanalizações/Cards/CardsAC.css'
import ruturas from '../../../../Imagens/ruturas.jpg'
import inox from '../../../../Imagens/Inox.jpg'
import colunas from '../../../../Imagens/colunasAgua.jpg'
import camadas from '../../../../Imagens/multicamadas.jpg'

function Card() {
    return (
        <div className='cardsACBackground'>
        <div className='cardsContainerAC'>
        <div className='tituloCardsContainerAC'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Instalamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsAC'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsAC'>
                        <div className='tituloCardAC'>
                            <h3>Água em Inox</h3>
                        </div>
                        <div className='cardImgAC'>
                        <img className='cardImgAC' src={inox}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsAC'>
                        <div className='tituloCardAC'>
                            <h3>Água em Multicamada</h3>
                        </div>
                        <div className='cardImgAC'>
                        <img className='cardImgAC' src={camadas}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsAC'>
                        <div className='tituloCardAC'>
                            <h3>Colunas Novas</h3>
                        </div>
                        <div className='cardImgAC'>
                        <img className='cardImgAC' src={colunas}></img>
                        </div>
                    </motion.div>
            </div>
        <div className='tituloCardsContainerAC'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Reparamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCardsAC'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='CardsAC'>
                        <div className='tituloCardAC'>
                            <h3>Ruturas de Água</h3>
                        </div>
                        <div className='cardImgAC'>
                        <img className='cardImgAC' src={ruturas}></img>
                        </div>
                    </motion.div>
            </div>
            
        </div>
        
    </div>
  
    );
  }
  
  export default Card;
