import React from 'react';
import './Servicos.css';
import {ButtonCaldeirasEsquentadores } from '../Button/Button';


function ServicosCE({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  alt,
  imgStart
}) {
  return (
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                <ButtonCaldeirasEsquentadores
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                 >
                Saiba Mais
                </ButtonCaldeirasEsquentadores>
               
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
                <img src={'https://www.dormarhvac.com/wp-content/uploads/2019/07/hot-water-electric-water-heater.png'} alt={alt} className='home__hero-img' />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ServicosCE;