import React, { Component } from 'react'
import './MarcasCE.css'
import Slider from "react-slick";
import marca1 from '../../../../Imagens/Marcas/vulcano.png';
import marca2 from '../../../../Imagens/Marcas/Vaillant.png';
import marca3 from '../../../../Imagens/Marcas/Baxi.jpg';
import marca4 from '../../../../Imagens/Marcas/Roca.jpg';
import marca5 from '../../../../Imagens/Marcas/Junkers.png';
export default class MarcasCE extends Component {
    render() {
        const settings = {
            centerMode: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 500,
            infinite: true,
            
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                    
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  
                }
                
              },
              {
                breakpoint: 320,
                settings: {
                    dots:true,
                    autoplay:false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                
                }
              },
            ]
          };
        return (
            <div>
                <div className='marcasCE'>
                    <div className='containerMarcasCE'>
                        <h1 className='tituloMarcasCE'>Marcas</h1>
                        <h4 className='descMarcasCE'>Efetuamos Reparações e Instalações de Esquentadores e Caldeiras das Marcas VULCANO, VAILLANT, ROCA, BAXI, JUNKERS, entre outras.. </h4>
                        <hr className='dividerServ'></hr>
                        <Slider  className='sliderCE' {...settings}>
                            <div>
                                <img className='imgMarcasCE' src={marca1} />
                            </div>
                            <div>
                                <img className='imgMarcasCE' src={marca2} />
                            </div>
                            <div>
                                <img className='imgMarcasCE' src={marca3} />
                            </div>
                            <div>
                                <img className='imgMarcasCE' src={marca4} />
                            </div>
                            <div>
                                <img className='imgMarcasCE' src={marca5} />
                            </div>
                        </Slider>
                        <hr className='divider'></hr>
                    </div>
                </div>
            </div>
        )
    }
}
