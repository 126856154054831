import React from 'react';
import Navbar from '../Eletrodomésticos/navbar/Navbar';
import Footer from '../../Footer/Footer';
import HeroER from '../EnergiasRenovaveis/Hero/HeroER';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InfoER from '../EnergiasRenovaveis/Info/InfoER';
import CardsER from '../EnergiasRenovaveis/Cards/CardsER';
import { motion } from 'framer-motion';
import ScrollingWrapper from '../../toTop/ToTop';

function EnergiasRenovaveis() {
  return (
    <>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}
    exit={{x:'-100vw',transition: {ease: 'easeInOut',duration:0.5}}}>
    <Navbar />
      <HeroER />
      <hr></hr>
      <InfoER/>
      <CardsER />
      <Footer />
      <ScrollingWrapper />
      </motion.div>
    </>
  );
}

export default EnergiasRenovaveis;
