import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';


const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

class Appa extends React.Component {
    componentDidMount() {
        this.props.hideLoader();
    }
    render() {
        return (
            <div>I'm the app</div>
        );
    }
}

// the setTimeout simulates the time it takes react to load, and is not part of the solution
setTimeout(() =>
// the show/hide functions are passed as props
    ReactDOM.render (
    <React.StrictMode>
        <Router >
            <App hideLoader={hideLoader}
                showLoader={showLoader}/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
), 1000);
