import React, {Component} from 'react'
import '../../ÁguasCanalizações/Info/infoAC.css';
import colunaAgua from '../../../../Imagens/Serviços/ColunaDeAgua.jfif';

export default class Info extends Component {
    render() {
        return (
            <div className='infoSectionAC' id='infoE'>
                <br></br>
                <div className='headerInfoAC'>

                    <div className='containerEletroAC'>
                        <div className='rowEletroAC'>
                            <div className='columnEletroAC'>
                                <div className='caixaAC'>
                                   
                                    <p className='descCaixaAC'>A <strong>WeRepair</strong> disponibiliza aos seus clientes um serviço de instalação de redes de água, garantindo sempre a utilização de materiais certificados bem como a rapidez na execução da obra.
                                        <br></br><br></br>
                                        •	Instalação de colunas montantes <br></br><br></br>
                                        •	Reparação de canalizações
                                    </p>
                                </div>
                            </div>
                            <div className='columnEletroAC'>
                                <img src={colunaAgua}
                                    className='imgColunaAgua'/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
