import React, { Component } from 'react'
import './Marcas.css'
import Slider from "react-slick";
import marca1 from '../../Imagens/Marcas/Bosch.png'
import marca2 from '../../Imagens/Marcas/chaffo.png'
import marca3 from '../../Imagens/Marcas/Hotpoint.png'
import marca4 from '../../Imagens/Marcas/Junkers.png'
import marca5 from '../../Imagens/Marcas/lg.png'
import marca6 from '../../Imagens/Marcas/Roca.jpg'
import marca7 from '../../Imagens/Marcas/teka.png'
import marca8 from '../../Imagens/Marcas/Vaillant.png'
import marca9 from '../../Imagens/Marcas/vulcano.png'
import marca10 from '../../Imagens/Marcas/whirlpool.png'
import marca11 from '../../Imagens/Marcas/Riello.jpg'
import marca12 from '../../Imagens/Marcas/Imergas.png'
import marca13 from '../../Imagens/Marcas/Fagor.jpg'
import marca14 from '../../Imagens/Marcas/Junex.jpg'
import marca15 from '../../Imagens/Marcas/dietrich.jpg'
import marca16 from '../../Imagens/Marcas/Edesa.png'
import marca17 from '../../Imagens/Marcas/Baxi.jpg'
import marca18 from '../../Imagens/Marcas/Beretta.jpg'

export default class Marcas extends Component {
    render() {
        const settings = {
            adaptiveHeight: true,
            centerMode: true,
            infinite: false,
            speed: 500,
            slidesToShow:5,
            slidesToScroll: 4,
            initialSlide: 0,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 500,
            infinite: true,
            
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
            
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
            <div>
                <div className='marcas'>
                    <div className='containerMarcas'>
                        <h1 className='tituloMarcas'>As Nossas Marcas</h1>
                        <h4 className='descMarcas'>Efetuamos reparaçoes das Marcas BOSCH, CHAFFO, HOTPOINT, JUNKERS, LG, ROCA, TEKA, VAILLANT, VULCANO, WHIRLPOOL, FAGOR, JUEX, DIETRICH, EDESA, BAXI, BERETTA, entre outras..</h4>
                        <hr className='dividerServ'></hr>
                        <Slider  className='slider' {...settings}>
                            <div>
                                <img className='imgMarcas' src={marca1} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca2} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca3} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca4} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca5} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca6} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca7} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca8} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca9} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca10} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca11} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca12} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca13} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca14} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca15} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca16} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca17} />
                            </div>
                            <div>
                                <img className='imgMarcas' src={marca18} />
                            </div>
                        </Slider>
                        <hr className='divider'></hr>
                    </div>
                </div>
            </div>
        )
    }
}
