import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './NavbarE.css';
import logo from '../../../../Imagens/banner.png'
import {ButtonInicio} from '../../../Button/Button';
import {Link} from 'react-router-dom';
import {Link as LinkS,animateScroll as scroll} from 'react-scroll';
import { RiArrowDropDownFill } from "react-icons/ri";
function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const toggleUp = () =>{
    scroll.scrollToTop();
  }
 
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbarE'>
        <div className='navbar-container'>
          <div className='logonav'>
          <Link to='/'>
        <img src={logo} alt="" className='navbar-logoS' ></img>
        </Link>
          </div>
          
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times ' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <div class="dropdown">
              <button class="dropbtn">Serviços<RiArrowDropDownFill size={35} className='arrowdown' /></button>
              <div class="dropdown-content">
                <Link to='/Eletrodomésticos'>Eletrodomésticos</Link>
                <Link to='ÁguasCanalizações'>Águas e Canalizações</Link>
                <Link to='Gás'>Gás</Link>
                <Link to='EnergiasRenováveis'>Energias Renováveis</Link>
                <Link to='CaldeirasEsquentadores'>Caldeiras e Esquentadores</Link>
              </div>
            </div>
            </li>
           <li>
             <Link to='/Eletrodomésticos' className='links-mobile' onClick={closeMobileMenu}>
             Eletrodomésticos
             </Link>
           </li>
           <li>
             <Link to='/ÁguasCanalizações' className='links-mobile' onClick={closeMobileMenu}>
             Águas e Canalizações
             </Link>
           </li>
           <li>
             <Link to='/Gás' className='links-mobile' onClick={closeMobileMenu}>
             Gás
             </Link>
           </li>
           <li>
             <Link to='/EnergiasRenováveis' className='links-mobile' onClick={closeMobileMenu}>
             Energias Renováveis
             </Link>
           </li>
           <li>
             <Link to='/CaldeirasEsquentadores' className='links-mobile' onClick={closeMobileMenu}>
             Caldeiras e Esquentadores
             </Link>
           </li>
            <li>
              <Link
                to='/'
             
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Início
              </Link>
            </li>
          </ul>
          <Link to='/' className='btn-mobilee'><ButtonInicio buttonStyle='btn--primary'>Início</ButtonInicio></Link>
        </div>
      </nav>
    </>
  );
}

export default Navbar;