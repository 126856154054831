import React from 'react';
import Navbar from '../Eletrodomésticos/navbar/Navbar';
import Footer from '../../Footer/Footer';
import HeroCE from '../CaldeirasEsquentadores/HeroCE/HeroCE';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InfoCE from '../CaldeirasEsquentadores/InfoCE/InfoCE';
import CardsCE from '../CaldeirasEsquentadores/CardsCE/CardsCE.js';
import MarcasCE from '../CaldeirasEsquentadores/Marcas/MarcasCE';
import Credencial from '../../Credencial/Credencial';
import GridCE from '../CaldeirasEsquentadores/Grid/GridCE';
import Contactos from '../../ContactoServiços/ContactoServiços';
import { motion } from 'framer-motion';
import ScrollingWrapper from '../../toTop/ToTop';

function CaldeirasEsquentadores() {
  return (
    <>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}
    exit={{x:'-100vw',transition: {ease: 'easeInOut',duration:0.5}}}>
    <Navbar />
      <HeroCE />
      <hr></hr>
      <InfoCE/>
      <CardsCE />
      <MarcasCE />
      <Credencial />
      <GridCE />
      <Contactos />
      <Footer />
      <ScrollingWrapper />
      </motion.div>
    </>
  );
}

export default CaldeirasEsquentadores;
