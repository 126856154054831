import React from 'react';
import '../Hero/HeroER.css';

function HeroER() {
  return (
    <div className='containerHeroER' >
      <div className='conteudoHeroER'>

      <h1 className='tituloER'><span className='eletro'>ENERGIAS </span><span className='domesticos'>RENOVÁVEIS</span></h1>

      </div>

      </div>

  );
}

export default HeroER;