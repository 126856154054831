import React, { Component } from 'react'
import {motion} from 'framer-motion'
import './Card.css'
import maquinaLavar from '../../../../Imagens/maquinaLavar.jpg'
import exaustor from '../../../../Imagens/exaustor.jpg'
import forno from'../../../../Imagens/Forno.jpg'
import louça from '../../../../Imagens/Louça.jpg'
import placa from '../../../../Imagens/placainducao.jpg'

function Card() {
    return (
        <motion.div
        
        className='cardsBackground'>
        <div className='cardsContainer'>
        <div className='tituloCardsContainer'>
                <br></br><br></br>
                <h3 className='tituloReparamos'>Reparamos e Instalamos</h3>
                <hr className='hrCards'></hr>
            </div>
            <div className='rowCards'>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='Cards'>
                        <div className='tituloCard'>
                            <h3>Máquinas de Lavar Roupa</h3>
                        </div>
                        <div className='cardImg'>
                        <img className='cardImg' src={maquinaLavar}></img>
                        </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='Cards'>
                    <div className='tituloCard'>
                        <h3>Máquinas de Lavar Louça</h3>
                    </div>
                    <div className='cardImg'>
                        <img className='cardImg' src={louça}></img>
                    </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='Cards'>
                        <div className='tituloCard'>
                            <h3>Placas de Indução</h3>
                        </div>
                        <div className='cardImg'>
                        <img className='cardImg' src={placa} ></img>
                        </div>
                      
                    </motion.div>
                     <motion.div 
                        whileHover={{scale:1.2}}
                        className='Cards'>
                            <div className='tituloCard'>
                                <h3>Fornos</h3>
                            </div>
                            <div className='cardImg'>
                            <img className='cardImg' src={forno}></img>
                            </div>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.2}}
                    className='Cards'>
                         <div className='tituloCard'>
                            <h3>Exaustores</h3>
                        </div>
                        <div className='cardImg'>
                        <img className='cardImg' src={exaustor} ></img>
                        </div>
                    </motion.div>
            </div>
        </div>
    </motion.div>
  
    );
  }
  
  export default Card;
