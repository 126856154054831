import React from 'react';
import Navbar from '../Eletrodomésticos/navbar/Navbar';
import Footer from '../../Footer/Footer';
import HeroG from '../Gás/Hero/HeroG';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InfoG from '../Gás/Info/InfoG';
import CardsG from '../Gás/Cards/CardsG';
import Credencial from '../../Credencial/Credencial';
import contactoservicos from '../../ContactoServiços/ContactoServiços'
import { motion } from 'framer-motion';
import ScrollingWrapper from '../../toTop/ToTop';
import Fugas from '../../pages/Gás/Fugas/Fugas';
import ContactoServiços from '../../Contactos/Contactos';

function Gas() {
  return (
    <>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}
    exit={{x:'-100vw',transition: {ease: 'easeInOut',duration:0.5}}}>
    <Navbar />
      <HeroG />
      <hr></hr>
      <Credencial />
      <hr></hr>
      <InfoG />
      <CardsG />
      <Fugas />
      <ContactoServiços />
      <Footer />
      <ScrollingWrapper />
      </motion.div>
    </>
  );
}

export default Gas;
