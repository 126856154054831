import React, {Component} from 'react'
import '../../Gás/Info/InfoG.css';
import Slider from "react-slick";
import ColunaGas from '../../../../Imagens/Serviços/ColunaGas.jfif';
import ColunaGas2 from '../../../../Imagens/Serviços/ColunaGas2.jfif';
import ColunaGas3 from '../../../../Imagens/Serviços/ColunaGas3.jfif';
import ColunaGas4 from '../../../../Imagens/Serviços/ColunaGas4.jfif';
import instalacao from '../../../../Imagens/Serviços/InstalacaoGas.jfif';
import co2 from '../../../../Imagens/Serviços/CO.jfif';
export default class Info extends Component {
    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 3000


        };
        return (
            <div className='infoSectionG' id='infoE'>
                <br></br>
                <div className='headerInfoG'>

                    <div className='containerEletroG'>
                        <div className='rowEletroG'>
                            <div className='columnEletroG'>
                                <div className='caixaG'>
                                    <p className='descCaixaG'>Somos uma empresa certificada pela <strong>DGEG</strong>, e temos ao seu dispor técnicos certificados, com vários anos de experiência em instalações, reparações e testes em redes de gás, sempre com elevado sentido de responsabilidade e profissionalismo, garantindo sempre a satisfação dos nossos clientes.
                                    <br></br> 
                                    <br></br>    •	Instalação e manutenção de redes de gás
                                    <br></br> <br></br>     •	Reparação de fugas em instalações de gás 
                                    <br></br> <br></br>     •	Tratamento internos a tubagens de gás (Reparação de fugas)
                                    <br></br> <br></br>    •	Reparação de Excesso de CO
                                    <br></br> <br></br>     •	Realização de testes estanquidade
                                    <br></br> <br></br>     •	Realização de testes de monóxido
                                    </p>
                                </div>


                            </div>
                            <div className='columnEletroG'>
                                <Slider className='sliderGas' {...settings}>
                                    <div>
                                        <img className='imgSlider'
                                            src={ColunaGas}/>
                                    </div>
                                    <div>
                                        <img className='imgSlider'
                                            src={ColunaGas2}/>
                                    </div>
                                    <div>
                                        <img className='imgSlider'
                                            src={ColunaGas3}/>
                                    </div>
                                    <div>
                                        <img className='imgSlider'
                                            src={ColunaGas4}/>
                                    </div>
                                    <div>
                                        <img className='imgSlider'
                                            src={instalacao}/>
                                    </div>
                                </Slider>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
