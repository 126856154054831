import React, { Component } from 'react'
import './Credencial.css'
import entidade from '../../Imagens/geologia.png'
export default class Credencial extends Component {
    render() {
        return (
            <div>
                <div className='credencialContainer'>
                    <div className='rowCredencial'>
                        <div className='backCredencial'>
                        <div className='columnCredenciall'>
                            <img className='imgCredencial' src={entidade}></img>
                        </div>
                        <div className='columnCredencial'>
                            <h1 className='h1Credencial'>Detentora da credencial, nº94/EI Tipo A+B, emitida pela <strong>Direção-Geral de Energia e Geologia</strong></h1>
                        </div>
                        <div className='columnCredencial'>
                            <img className='imgCredencial' src={entidade}></img>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
