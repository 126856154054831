import React from 'react';
import Navbar from '../Eletrodomésticos/navbar/Navbar';
import Footer from '../../Footer/Footer';
import HeroE from '../Eletrodomésticos/Hero/heroE'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Info from '../Eletrodomésticos/Info/Info';
import Card from '../Eletrodomésticos/Cards/Card';
import ScrollingWrapper from '../../toTop/ToTop';
import ContactoServiços from '../../Contactos/Contactos';
import Galeria from '../Eletrodomésticos/Galeria/GaleriaE'
import { motion } from 'framer-motion';


function Eletrodomesticos() {
  return (
    <>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}
    exit={{x:'-100vw',transition: {ease: 'easeInOut',duration:0.5}}}>
    <Navbar />
      <HeroE />
      <hr></hr>
      <Info/>
      <Card />
      <Galeria />
      <ContactoServiços />
      <Footer />
      <ScrollingWrapper />
      </motion.div>
    </>
  );
}

export default Eletrodomesticos;
