import React, { Component } from 'react'
import './GridCE.css'
import imagem1 from '../../../../Imagens/CE/caldeira.jfif';
import imagem2 from '../../../../Imagens/CE/esquentador.jfif';
import imagem3 from '../../../../Imagens/CE/esquentador2.jfif';
import imagem4 from '../../../../Imagens/CE/esquentadorroca.jfif';
import imagem5 from '../../../../Imagens/CE/esquientador3.jfif';
import imagem6 from '../../../../Imagens/CE/radiador.jfif';
import {motion} from 'framer-motion';
export default class GridCE extends Component {
    render() {
        return (
            <div>
                <div className='gridCEContainer'>
                    <motion.div 
                    whileHover={{scale:1.3}}
                     className='gridCEColumn'>
                        <img className='imgGridCE' src={imagem1}>

                        </img>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.3}}
                     className='gridCEColumn'>
                    <img className='imgGridCE' src={imagem2}>

</img>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.3}} className='gridCEColumn'>
                    <img className='imgGridCE' src={imagem3}>

</img>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.3}} className='gridCEColumn'>
                    <img className='imgGridCE' src={imagem4}>

</img>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.3}} className='gridCEColumn'>
                    <img className='imgGridCE' src={imagem5}>

</img>
                    </motion.div>
                    <motion.div 
                    whileHover={{scale:1.3}} className='gridCEColumn'>
                    <img className='imgGridCE' src={imagem6}>

</img>
                    </motion.div>
                </div>
            </div>
        )
    }
}
